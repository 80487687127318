<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Registros de DataTables">
        <Toolbar class="p-mb-3">
          <template v-slot:left>
            <b>SELECIONE:</b> 
            <Dropdown
              id="dropSelectDt"
              v-model="selectedDatatable"
              :options="datatables"
              :filter="true"
              optionLabel="nome"
              placeholder="Escolha o Datatable"
              @change="searchDatatable(selectedDatatable)"
              class="p-mr-2 p-ml-2"
            >
            </Dropdown>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success"
              :disabled="!selectedDatatable"
              @click="openCreateRegistro()"
            />
          </template>
          <template v-slot:right> </template>
        </Toolbar>
        <DataTable
          :value="dados"
          responsiveLayout="scroll"
          ref="dt"
          class="p-datatable-sm"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros."
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5>{{ nome.toUpperCase() }}</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column
            v-for="col of columns"
            :field="col.field"
            :header="col.header.toUpperCase()"
            :key="col.type"
            :sortable="true"
          >
            <template #body="slotProps">
              <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
              <div v-if="slotProps.column.key === 'UNIDADE_GESTORA'">
                <small>
                  {{ slotProps.data[col.field] }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

              <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
              <div v-if="slotProps.column.key === 'NUMERAL'">
                <small>
                  {{ slotProps.data[col.field] }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

              <!-- EM CASO DO TIPO LINK -->
              <div v-if="slotProps.column.key === 'LINK'">
                <small>
                  <a :href="slotProps.data[col.field]" target="_blank"
                    >Acessar Link</a
                  >
                </small>
              </div>
              <!-- EM CASO DO TIPO LINK -->

              <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->
              <div v-if="slotProps.column.key === 'LONGTEXTO'">
                <div class="myBox">
                  <small>
                    {{ slotProps.data[col.field].toUpperCase() }}
                  </small>
                </div>
              </div>
              <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->

              <!-- EM CASO DO TIPO DE TEXTO -->
              <div v-if="slotProps.column.key === 'TEXTO'">
                <small v-tooltip.top="slotProps.data[col.field]">
                  {{ slotProps.data[col.field].toUpperCase() }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE TEXTO -->

              <!-- EM CASO DO TIPO DE DATA -->
              <div v-if="slotProps.column.key === 'DATE'">
                <small>
                  {{ $DateTime.formatDate(slotProps.data[col.field]) }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE DATA -->

              <!-- EM CASO DO TIPO DE HORA -->
              <div v-if="slotProps.column.key === 'TIME'">
                <small>
                  {{ slotProps.data[col.field] }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE HORA -->

              <!-- EM CASO DO TIPO DE DATA E HORA -->
              <div v-if="slotProps.column.key === 'DATETIME'">
                <small>
                  {{ $DateTime.formatDateTime24hours(slotProps.data[col.field]) }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE DATA E HORA -->

              <!-- EM CASO DO TIPO DE VALORES -->
              <div v-if="slotProps.column.key === 'VALOR'">
                <small>
                  {{ slotProps.data[col.field] }}
                </small>
              </div>
              <!-- EM CASO DO TIPO DE VALORES -->

              <!-- EM CASO DO TIPO DE BOOLEAN -->
              <div v-if="slotProps.column.key === 'BOOLEAN'">
                <span
                  v-if="slotProps.data[col.field] === 'true'"
                  class="product-badge status-true"
                  >ATIVO</span
                >
                <span
                  v-if="slotProps.data[col.field] === 'false'"
                  class="product-badge status-false"
                  >Inativo</span
                >
              </div>
              <!-- EM CASO DO TIPO DE BOOLENAN -->

              <!-- CASO FOR UPLOAD -->
              <div v-if="slotProps.column.key === 'UPLOAD'">
                <Button
                  label="ANEXO"
                  class="p-button-raised p-button-secondary"
                  icon="fa-solid fa-folder"
                  @click="openAnexo(slotProps.data[col.field])"
                />
              </div>
              <!-- CASO FOR UPLOAD -->
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="openUpdateRegistro(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-sort-alt"
                class="p-button-rounded p-button p-mr-2"
                v-if="
                  $Token.tokenParams().grupo === 'ADMINISTRADOR' ||
                  $Token.tokenParams().grupo === 'GESTOR DE DADOS'
                "
                @click="openHabilitarAndDesabilitrarRegistro(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA HABILITAR/DESABILITAR'"
              />
              <Button
                icon="pi pi-align-right"
                class="p-button-rounded p-button-secondary p-mr-2"
                v-if="
                  $Token.tokenParams().grupo === 'ADMINISTRADOR' ||
                  $Token.tokenParams().grupo === 'GESTOR DE DADOS'
                "
                @click="openLog(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA VERIFICAR LOG'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                v-if="$Token.tokenParams().grupo === 'ADMINISTRADOR'"
                @click="openDeleteRegistro(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
    </Panel>
    </div>
  </div>

  <!-- MODAL DE ANEXOS -->
  <Dialog
    v-model:visible="dialogLog"
    header="REGISTROS DE LOG'S"
    :style="{ width: '650px' }"
    :modal="true"
  >
    <Toolbar class="p-mb-3">
      <template v-slot:left>
        <ul>
          <li>
            <span class="product-badge status-post">POST</span>
            <b> - CADASTRO DE REGISTRO</b>
          </li>
          <li class="p-mt-2">
            <span class="product-badge status-put">PUT</span>
            <b> - ALTERAÇÃO DE REGISTRO</b>
          </li>
          <li class="p-mt-2">
            <span class="product-badge status-delete">DELETE</span>
            <b> - REMOÇÃO DE REGISTRO</b>
          </li>

          <li class="p-mt-2">
            <span class="product-badge status-get">GET</span>
            <b> - LISTAGEM DE UM OU MAIS REGISTROS</b>
          </li>
        </ul>
      </template>
      <template v-slot:right> </template>
    </Toolbar>

    <OrderList v-model="logs" listStyle="height:auto" dataKey="vin">
      <template #header> Lista de Log </template>
      <template #item="slotProps">
        <div class="p-caritem">
          <span> <b>Descrição: </b>{{ slotProps.item.descricao }}</span
          ><br />
          <span><b>Responsavél: </b>{{ slotProps.item.responsavel }}</span
          ><br />
          <span> <b>Operação: </b>{{ slotProps.item.operacao }}</span
          ><br />
          <span> <b>Origem: </b> {{ formatIp(slotProps.item.ip) }}</span
          ><br />
          <span>
            <b>Data da Operação: </b>
            {{
              $DateTime.formatDateTime24hours(slotProps.item.dt_operacao)
            }}</span
          >
        </div>
      </template>
    </OrderList>
  </Dialog>
  <!-- FIM MODAL DE ANEXOS -->

  <!-- MODAL DE ANEXOS -->
  <Dialog
    v-model:visible="dialogAnexo"
    header="LISTA DE ANEXOS"
    :style="{ width: '650px' }"
    :modal="true"
  >
    <div class="p-d-flex p-ai-center p-jc-center p-mb-3">
      <InputText
        id="uploadAnexo"
        type="file"
        accept="application/pdf,.xls,.xlsx,csv,.doc,.docx"
        @change="uploaderAnexo"
      />
    </div>
    <ProgressBar
      v-if="progressbar"
      mode="indeterminate"
      style="height: 0.5em"
    />
    <div class="p-d-flex p-ai-center p-jc-center p-mb-3">
      <small
        >Em caso de algum <b>impedimento com tamanho do arquivo</b> utilize
        alguma das ferramentas para compressão. Clique nos links :
        <a
          href="https://www.ilovepdf.com/pt/comprimir_pdf"
          target="_blank"
          rel="noopener noreferrer"
          >ILove PDF</a
        >
        /
        <a
          href="https://pdfcompressor.com/pt/"
          target="_blank"
          rel="noopener noreferrer"
          >PDF Compressor</a
        >

        /
        <a
          href="https://smallpdf.com/pt/comprimir-pdf"
          target="_blank"
          rel="noopener noreferrer"
          >Small PDF</a
        >.
      </small>
      <small> </small>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <DataTable
          :value="anexos"
          :paginator="true"
          :rows="5"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} anexos."
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="p-text-center">Nenhum anexo encontrado...</div>
          </template>
          <Column field="id" header="#"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="identificador" header="Identificador"></Column>
          <Column field="url" header="Anexo">
            <template #body="slotProps">
              <Button
                label="DOWNLOAD"
                class="p-button-raised p-button-success"
                icon="pi pi-download"
                v-tooltip.top="'CLIQUE PARA BAIXAR/VISUALIZAR'"
                @click="showAnexo(slotProps.data.url)"
              />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-times"
                class="p-button-rounded p-button-secondar"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
                :disabled="disabledButton"
                @click="deleteAnexo(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <template #footer> </template>
  </Dialog>
  <!-- FIM MODAL DE ANEXOS -->

  <!-- MODAL DE CADASTRO-->
  <Dialog
    v-model:visible="dialogCreate"
    header="CADASTRO DE REGISTROS"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12" v-for="col in columns" :key="col.idColuna">
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
        <div v-if="col.type === 'NUMERAL'">
          <label :for="col.header">{{ col.header }}</label>
          <InputNumber
            :id="col.header"
            mode="decimal"
            :useGrouping="false"
            placeholder="Informe o valor deste campo"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
        <div v-if="col.type === 'UNIDADE_GESTORA'">
          <label :for="col.header">{{ col.header }}</label>
          <Dropdown
            :id="col.header"
            v-model="col.value"
            :options="unidadesGestoras"
            optionLabel="nome"
            optionValue="nome"
            placeholder="Informe o valor deste campo"
          />
        </div>
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

        <!-- EM CASO DO TIPO LINK -->
        <div v-if="col.type === 'LINK'">
          <label :for="col.header">{{ col.header }}</label>
          <InputText
            :id="col.header"
            placeholder="Informe o valor deste campo"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO LINK -->

        <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->
        <div v-if="col.type === 'LONGTEXTO'">
          <label :for="col.header">{{ col.header }}</label>
          <Textarea
            :id="col.header"
            rows="5"
            cols="30"
            placeholder="Informe o valor deste campo"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->

        <!-- EM CASO DO TIPO DE TEXTO -->
        <div v-if="col.type === 'TEXTO'">
          <label :for="col.header">{{ col.header }}</label>
          <InputText
            :id="col.header"
            placeholder="Informe o valor deste campo"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO DE TEXTO -->

        <!-- EM CASO DO TIPO DE DATA -->
        <div v-if="col.type === 'DATE'">
          <label :for="col.header">{{ col.header }}</label>
          <InputText type="date" :id="col.header" v-model="col.value" />
        </div>
        <!-- EM CASO DO TIPO DE DATA -->

        <!-- EM CASO DO TIPO DE HORA -->
        <div v-if="col.type === 'TIME'">
          <label :for="col.header">{{ col.header }}</label>
          <InputText type="time" :id="col.header" v-model="col.value" />
        </div>
        <!-- EM CASO DO TIPO DE HORA -->

        <!-- EM CASO DO TIPO DE DATA E HORA -->
        <div v-if="col.type === 'DATETIME'">
          <label :for="col.header">{{ col.header }}</label>
          <InputText
            type="datetime-local"
            :id="col.header"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO DE DATA E HORA -->

        <!-- EM CASO DO TIPO DE VALORES -->
        <div v-if="col.type === 'VALOR'">
          <label :for="col.header">{{ col.header }}</label>
          <InputNumber
            :id="col.header"
            :format="true"
            mode="currency"
            currency="BRL"
            locale="pt-br"
            placeholder="Informe o valor deste campo"
            v-model="col.value"
          />
        </div>
        <!-- EM CASO DO TIPO DE VALORES -->

        <!-- EM CASO DO TIPO DE BOOLEAN -->
        <div v-if="col.type === 'BOOLEAN'">
          <label :for="col.header">{{ col.header }}</label
          ><br class="p-mb-2" />
          <Dropdown
            v-model="col.value"
            :options="optionsBoolean"
            placeholder="Informe o valor deste campo"
            optionLabel="descricao"
            optionValue="option"
          />
          <!--<InputSwitch v-model="col.value" />-->
        </div>
        <!-- EM CASO DO TIPO DE BOOLENAN -->
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="saveRegistro"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs"
      />
    </template>
  </Dialog>
  <!-- MODAL DE CADASTRO FIM-->

  <!-- MODAL DE ATUALIZAÇÃO-->
  <Dialog
    v-model:visible="dialogUpdate"
    header="ATUALIZAÇÃO DE REGISTROS"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12" v-for="col in columnsUpdate" :key="col.id">
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
        <div v-if="col.colums.tipo === 'UNIDADE_GESTORA'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <Dropdown
            :id="col.colums.nome"
            v-model="col.descricao"
            :options="unidadesGestoras"
            optionLabel="nome"
            optionValue="nome"
            placeholder="Informe o valor deste campo"
          />
          <!--
          <InputNumber
            :id="col.colums.nome"
            mode="decimal"
            :useGrouping="false"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
          -->
        </div>
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
        <div v-if="col.colums.tipo === 'NUMERAL'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputNumber
            :id="col.colums.nome"
            mode="decimal"
            :useGrouping="false"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

        <!-- EM CASO DO TIPO LINK -->
        <div v-if="col.colums.tipo === 'LINK'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            :id="col.colums.nome"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO LINK -->

        <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->
        <div v-if="col.colums.tipo === 'LONGTEXTO'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <Textarea
            :id="col.colums.nome"
            rows="5"
            cols="30"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->

        <!-- EM CASO DO TIPO DE TEXTO -->
        <div v-if="col.colums.tipo === 'TEXTO'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            :id="col.colums.nome"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE TEXTO -->

        <!-- EM CASO DO TIPO DE DATA -->
        <div v-if="col.colums.tipo === 'DATE'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            type="date"
            :id="col.colums.nome"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE DATA -->

        <!-- EM CASO DO TIPO DE HORA -->
        <div v-if="col.colums.tipo === 'TIME'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            type="time"
            :id="col.colums.nome"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE HORA -->

        <!-- EM CASO DO TIPO DE DATA E HORA -->
        <div v-if="col.colums.tipo === 'DATETIME'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            type="datetime-local"
            :id="col.colums.nome"
            v-model="col.descricao"
          />
        </div>
        <!-- EM CASO DO TIPO DE DATA E HORA -->

        <!-- EM CASO DO TIPO DE VALORES -->
        <div v-if="col.colums.tipo === 'VALOR'">
          <label :for="col.colums.nome">{{
            col.colums.nome.toUpperCase()
          }}</label>
          <InputText
            :id="col.colums.nome"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
          <!--
          <InputNumber
            :id="col.colums.nome"
            :format="true"
            mode="currency"
            currency="BRL"
            locale="pt-br"
            placeholder="Informe o valor deste campo"
            v-model="col.descricao"
          />
          -->
        </div>
        <!-- EM CASO DO TIPO DE VALORES -->

        <!-- EM CASO DO TIPO DE BOOLEAN -->
        <div v-if="col.colums.tipo === 'BOOLEAN'">
          <label :for="col.colums.nome">{{ col.colums.nome }}</label>
          <br class="p-mb-2" />
          <Dropdown
            v-model="col.descricao"
            :options="optionsBoolean"
            optionLabel="descricao"
            optionValue="option"
          />
          <!--
          <small>Este campo encontra-se: {{ col.descricao }}</small>
          <InputSwitch :id="col.colums.nome" v-model="col.descricao" />
          -->
        </div>
        <!-- EM CASO DO TIPO DE BOOLENAN -->
      </div>
    </div>

    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="updatedRegistro"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs"
      />
    </template>
  </Dialog>
  <!-- MODAL DE ATUALIZAÇÃO FIM-->

  <!-- MODAL DE HABILITAR E DESABILITAR-->
  <Dialog
    v-model:visible="dialogHablitarAndDesabilitar"
    :header="titleDialog"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <span
        >Tem certeza de que deseja <b>habilitar ou desabilitar</b> este
        registro?
      </span>
    </div>
    <template #footer>
      <Button
        label="Habilitar"
        icon="pi pi-unlock"
        class="p-button-raised p-button-success"
        :disabled="disabledButtonHabilitarAndDesabilitar === true"
        @click="updateStatus(true)"
      />
      <Button
        label="Desabilitar"
        icon="pi pi-lock"
        class="p-button-raised p-button-danger"
        :disabled="disabledButtonHabilitarAndDesabilitar === false"
        @click="updateStatus(false)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!-- FIM MODAL DE HABILITAR E DESABILITAR-->

  <!-- MODAL DE DELETAR-->
  <Dialog
    v-model:visible="dialogDelete"
    :header="titleDialog"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span
        >Tem certeza de que deseja excluir do sistema este
        <b> registro do sistema ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteRegistro()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!-- FIM MODAL DE DELETAR-->
</template>

<script>
import { FilterMatchMode } from "primevue/api";

import DatatableService from "@/service/Datatable/DatatableService";
import ColumsService from "@/service/Colums/ColumsService";
import RegistroService from "@/service/Registro/RegistroService";
import AnexoService from "@/service/Anexo/AnexoService";
import LogService from "@/service/Log/LogService";
import UnidadesService from "@/service/Unidades/UnidadesService";

export default {
  data() {
    return {
      progressbar: false,
      disabledButton: false,
      disabledButtonHabilitarAndDesabilitar: false,
      datatables: null,
      datatable: null,
      filters: {},
      columns: [],
      registro: [],
      dados: [],
      columnsUpdate: [],
      optionsBoolean: [
        { option: "true", descricao: "ATIVO" },
        { option: "false", descricao: "INATIVO" },
      ],
      anexo: {
        id: null,
        url: null,
        nome: null,
        identificador: null,
        dt_atualizacao: null,
        registro: {
          id: null,
        },
      },
      anexos: null,
      nome: "",
      texto: null,
      titleDialog: null,
      dialogAnexo: false,
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false,
      dialogHablitarAndDesabilitar: false,
      selectedAnexo: null,
      selectedRegistro: null,
      selectedDatatable: null,
      dialogLog: false,
      logs: null,
      subform: true,
      unidadesGestoras: null,
    };
  },
  datatableService: null,
  columsService: null,
  registroService: null,
  anexoService: null,
  logService: null,
  unidadesService: null,
  created() {
    this.initFilters();
    this.datatableService = new DatatableService();
    this.columsService = new ColumsService();
    this.registroService = new RegistroService();
    this.anexoService = new AnexoService();
    this.logService = new LogService();
    this.unidadesService = new UnidadesService();
    this.storageRef = this.$firebaseApp.storage();
  },
  mounted() {
    let user = this.$Token.payLoad();
    this.findByDatatableByUserId(user.id);
    this.findAllUnidades();
  },
  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    findByDatatableByUserId(data) {
      this.datatableService
        .findByDatatableByUserId(data)
        .then((data) => {
          this.datatables = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllUnidades() {
      this.unidadesService.findAll().then((data) => {
        this.unidadesGestoras = data;
      });
    },
    searchDatatable(data) {
      this.dados = [];
      this.columns = [];
      this.nome = this.selectedDatatable.nome.toUpperCase();
      this.searchColums(data.id);
      this.searchRegistro(data.id);
    },
    /*OK ESTA MONTANDO A ESTRUTURA DAS COLUNAS*/
    searchColums(param) {
      this.columsService
        .findByDatatableId(param)
        .then((data) => {
          data.forEach((element) => {
            //console.log(element);
            this.columns.push({
              idColuna: element.id,
              header: element.nome.toUpperCase(),
              field: element.nome,
              type: element.tipo,
              nameDatatable: element.datatable.nome.toUpperCase(),
              value: null,
            });
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    //OK MONTANDO A ESTRUTURA DOS REGISTRO (AINDA PRECISA SER MELHORADO)
    searchRegistro(param) {
      this.registroService
        .findByDatatableId(param)
        .then((data) => {
          let paramsData = [];
          for (var i = 0; i < data.length; i++) {
            if (data[i].colums.tipo === "UPLOAD") {
              var texto = "";
              texto =
                "{" +
                '"' +
                data[i].colums.nome +
                '"' +
                ":" +
                '"' +
                data[i].id +
                '",' +
                '"linha":' +
                data[i].ref +
                "," +
                '"coluna":' +
                data[i].colums.id +
                "}";
              //console.log("campo de anexo");
            } else {
              texto =
                "{" +
                '"' +
                data[i].colums.nome +
                '"' +
                ":" +
                '"' +
                data[i].descricao +
                '",' +
                '"linha":' +
                data[i].ref +
                "," +
                '"id":' +
                data[i].id +
                "}";
              //console.log("Não é campo de anexo");
            }
            var objeto = JSON.parse(texto);
            paramsData.push(objeto);
          }
          let agrupados = [];
          let quantRegistros = paramsData.length / this.columns.length;
          //GERANDO A QUANTIDADE DE LINHAS DO DATATABLE
          for (let i = 1; i <= quantRegistros; i++) {
            agrupados = [];
            //DESCOBRINDO QUAIS REGISTRO SÃO DA MESMA LINHA
            paramsData.forEach((element) => {
              if (element.linha === i) {
                agrupados.push(element);
              }
            });
            let obj = this.formatGroups(agrupados);
            this.dados.push(obj);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    formatGroups(param) {
      //MAXIMO DE 13 COLUNAS COMO PARAMETRO
      return Object.assign(
        {},
        param[0],
        param[1],
        param[2],
        param[3],
        param[4],
        param[5],
        param[6],
        param[7],
        param[8],
        param[9],
        param[10],
        param[11],
        param[12]
      );
    },
    openLog(registro) {
      this.logService
        .findByDatatableByRef(this.selectedDatatable.id, registro.linha)
        .then((data) => {
          this.logs = data;
          this.dialogLog = true;
        });
    },
    openAnexo(registro) {
      this.selectedAnexo = registro;
      this.findAnexoByRegistroId(registro);
      this.dialogAnexo = true;
    },
    openCreateRegistro() {
      this.dialogCreate = true;
    },
    openUpdateRegistro(registro) {
      this.registroService
        .findByDatatableByRef(registro.linha, this.selectedDatatable.id)
        .then((data) => {
          if (data[0].status === false || data[1].status === false) {
            this.$toast.add({
              severity: "info",
              summary: "Atenção!",
              detail:
                "Este registro encontra-se desabilitado. Para editar precisa habilita-lo.",
            });
          } else {
            this.columnsUpdate = data;
            this.dialogUpdate = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    openDeleteRegistro(registro) {
      this.selectedRegistro = registro;
      this.registroService
        .findByDatatableByRef(registro.linha, this.selectedDatatable.id)
        .then((data) => {
          if (data[0].status === false || data[1].status === false) {
            this.$toast.add({
              severity: "info",
              summary: "Atenção!",
              detail:
                "Este registro encontra-se desabilitado. Para editar precisa habilita-lo.",
            });
          } else {
            this.titleDialog = "Exclusão de registro";
            this.dialogDelete = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    openHabilitarAndDesabilitrarRegistro(registro) {
      this.registroService
        .findByDatatableByRef(registro.linha, this.selectedDatatable.id)
        .then((data) => {
          this.selectedRegistro = data;
          this.disabledButtonHabilitarAndDesabilitar = data[0].status;
          this.titleDialog = "Deseja Habilitar e/ou Desabilitar registros? ";
          this.dialogHablitarAndDesabilitar = true;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    saveRegistro() {
      //REALIZANDO A VALIDAÇÃO
      this.columns.forEach((element) => {
        if (
          element.type !== "UPLOAD" &&
          (element.value === null || element.value === " ")
        ) {
          this.subform = false;
          this.$toast.add({
            severity: "error",
            summary: "Alerta de erro.",
            detail: "Campo de " + element.field + " é obrigatório.",
            life: 10000,
          });
          return;
        }
      });
      this.sendSave(this.subform);
    },
    sendSave(param) {
      if (param === true) {
        this.columns.forEach((element) => {
          //TRATAMENTO PARA TIPO VALOR
          if (element.type === "VALOR") {
            element.value = element.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          }
          //TRATAMENTO PARA TIPO UPLOAD
          if (element.type === "UPLOAD") {
            this.registro = {
              id: null,
              colums: { id: element.idColuna },
              descricao: "ANEXO",
              ref: null,
              status: true,
            };
          } else {
            this.registro = {
              id: null,
              colums: { id: element.idColuna },
              descricao: this.formatDescricao(element.value),
              ref: null,
              status: true,
            };
          }

          this.registroService
            .create(this.registro)
            .then((data) => {
              this.$msgSuccess(data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$msgWarn(null);
              }
            });
          if (this.columns.length - 1 === this.columns.lastIndexOf(element)) {
            this.dados = [];
            this.columns = [];
            this.hideDialogs();
          }
        });
      } else {
        return;
      }
    },
    updatedRegistro() {
      this.columnsUpdate.forEach((element) => {
        this.registro = {
          id: element.id,
          colums: { id: element.colums.id },
          descricao: this.formatDescricao(element.descricao),
          ref: element.ref,
          status: true,
        };

        this.registroService
          .update(this.registro.id, this.registro)
          .then((data) => {
            this.$msgSuccess(data);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            }
          });
      });
      this.searchDatatable(this.selectedDatatable);
      this.hideDialogs();
    },
    deleteRegistro() {
      this.registroService
        .delete(this.selectedDatatable.id, this.selectedRegistro.linha)
        .then((data) => {
          this.$msgSuccess(data);
          this.dados = [];
          this.columns = [];
          this.hideDialogs();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$msgErro(error);
          }
        });
    },
    hideDialogs() {
      this.titleDialog = null;
      this.dialogAnexo = false;
      this.dialogCreate = false;
      this.dialogUpdate = false;
      this.dialogDelete = false;
      this.dialogHablitarAndDesabilitar = false;
      this.subform = true;
    },
    uploaderAnexo(event) {
      this.progressbar = true;
      this.disabledButton = true;
      //console.log(event.target.files);
      //var arquivo = event.files[0];
      var arquivo = event.target.files[0];
      //console.log(arquivo);

      if (arquivo.size > 12000000) {
        alert("Não foi possivel realizar esta ação! Arquivo acima de 12MB.");
        this.progressbar = false;
        this.disabledButton = false;
        return;
      }
      //console.log(arquivo);
      //gerando nome do arquivo de forma aleatoria
      let identificador = Math.floor(Date.now() * Math.random()).toString(18);
      this.storageRef
        .ref()
        .child(`DATATABLE_${this.selectedDatatable.id}/` + identificador)
        .put(arquivo)
        .then((snapshot) => {
          this.$toast.add({
            severity: "success",
            summary: "Alerta!",
            detail: "Upload realizado com sucesso.",
            life: 3000,
          });
          this.getDownloadURL(
            snapshot,
            this.selectedAnexo,
            identificador,
            arquivo.name
          );
        });
    },
    getDownloadURL(snapshot, idRegistro, descricao, nome) {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.anexo = {
          id: null,
          url: downloadURL,
          nome: nome,
          identificador: descricao,
          dt_cadastro: null,
          dt_atualizacao: null,
          registro: {
            id: idRegistro,
          },
        };
        this.saveAnexo(this.anexo);
      });
    },
    findAnexoByRegistroId(idRegistro) {
      this.anexoService
        .findByRegistroId(idRegistro)
        .then((data) => {
          this.anexos = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    saveAnexo(param) {
      this.anexoService
        .create(param)
        .then((data) => {
          if (data.status === 201) {
            this.progressbar = false;
            this.disabledButton = false;
            this.findAnexoByRegistroId(this.selectedAnexo);
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro salvo com sucesso.",
              life: 3000,
            });
            document.getElementById("uploadAnexo").value = "";
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    deleteAnexo(anexo) {
      this.progressbar = true;
      this.disabledButton = true;

      this.storageRef
        .ref()
        .child(`DATATABLE_${this.selectedDatatable.id}/` + anexo.identificador)
        .delete()
        .then(() => {
          this.deleteAnexoById(anexo.id);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    deleteAnexoById(id) {
      this.anexoService
        .delete(id)
        .then((data) => {
          if (data.status === 204) {
            this.disabledButton = false;
            this.progressbar = false;
            this.findAnexoByRegistroId(this.selectedAnexo);
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro excluido com sucesso.",
              life: 3000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    showAnexo(param) {
      window.open(param, "blank");
    },
    convertBoolean(param) {
      if (param === "false") {
        return false;
      } else {
        return true;
      }
    },
    updateStatus(param) {
      if (param === true) {
        this.selectedRegistro.forEach((element) => {
          this.registroService
            .updateStatus(element.id, true)
            .then((data) => {
              this.$msgSuccess(data);
              this.hideDialogs();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$msgWarn(null);
              }
            });
        });
      } else {
        this.selectedRegistro.forEach((element) => {
          this.registroService
            .updateStatus(element.id, false)
            .then((data) => {
              this.$msgSuccess(data);
              this.hideDialogs();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$msgWarn(null);
              }
            });
        });
      }
    },
    formatIp(param) {
      let containing = param.indexOf(", tokenType=BearertokenValue=<TOKEN>");
      if (containing > -1) {
        param = param.replace(", tokenType=BearertokenValue=<TOKEN>", "");
      }
      return param;
    },
    formatDescricao(param) {
      const str = param.toString().trim();
      return str.replaceAll('"', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.myBox {
  border: none;
  height: 80px;
  width: 650px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-get {
    background: #b3e5fc;
    color: #23547b;
  }
  &.status-post {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-put {
    background: #feedaf;
    color: #8a5340;
  }
  &.status-delete {
    background: #ffcdd2;
    color: #c63737;
  }
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>
