import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class UnidadesService extends AuthService {

  findAll() {
    return axios({
      method: 'get',
      url: this.api_url + 'unidades',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  create(setor) {
    return axios({
      method: 'post',
      url: this.api_url + 'unidades',
      withCredentials: true,
      data: setor,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  deleteById(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'unidades/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  update(id, data) {
    return axios({
      method: 'put',
      url: this.api_url + 'unidades/' + id,
      withCredentials: true,
      data: data,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
}
