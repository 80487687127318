import axios from "axios";
import AuthService from '../Auth/AuthService';
import { gerarUrl } from "../../utilities/pages/Pageable";

//import config from '../Auth/Config';

export default class DatatableService extends AuthService {

    findAll(queryParams) {
        queryParams = gerarUrl(queryParams);
        return axios({
            method: 'get',
            url: this.api_url + 'log?' +  queryParams,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }

    findByDatatableByRef(idDatatable,linha) {
        return axios({
            method: 'get',
            url: this.api_url + 'log/' + idDatatable + '/ ' + linha,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }

}
